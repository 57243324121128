@media (min-width: 767px){
    .dropdown-menu .dropdown-toggle:after{
        border-top: .3em solid transparent;
        border-right: 0;
        border-bottom: .3em solid transparent;
        border-left: .3em solid;
        position: absolute;
        top:13px;
    }


    .navbar_small{
        display:none
    }

    .userprofile2{
        display:none;
    }

    .dropdown-menu{
        margin-left:0; margin-right: 0;
    }

    .dropdown-menu li{
        position: relative;
    }
    .nav-item .submenu{ 
        display: none;
        position: absolute;
        left:100%; top:-7px;
    }
    .nav-item .submenu-left{ 
        right:100%; left:auto;
    }

    .dropdown-menu > li:hover > .submenu{
        display: block;
    }

    .navbar-collection{
        margin-right:10rem;
    }

    .navbar___container{
        justify-content: space-evenly;
        background-color: white !important;
        /* border: 3px solid blue; */   
    }
    
    
    .navbar_list{
        background-color: white !important;
        width:100px;
        margin-left:10rem;
    }

    .dropdown-item:active {
        color:black;
        background-color: white;
    }

    .dropdown-item:hover{
        background-color: #352E57;
        color:white;
    }
    
}


@media (max-width:600px){


    .navbar_large{
        display:none
    }
    

    .userprofile1{
        display:none;
    }

    .dropdown-menu{
        margin-left:0; margin-right: 0;
    }

    .dropdown-menu li{
        position: relative;
    }
    .nav-item .submenu{ 
        position: absolute;
       
    }
    .nav-item .submenu-left{ 
        right:100%; left:auto;
    }

    .dropdown-menu > li:hover{ background-color: #f1f1f1 }
    .dropdown-menu > li:hover > .submenu{
        display: block;
    }

    

}












