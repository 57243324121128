@media only screen and (min-width: 1025px) {

    .progress_loader {
        position: absolute;
        top: 300px;
        left: 450px;
        z-index: 1;
    }




    .highcharts-background {
        width: 100%
    }



    .dropdown-highchart {
        width: 95%;
        margin-left: 2.5rem;
    }



    .my_assetsfilter {
        margin-top: 2rem;
        margin-bottom: 6rem;

    }

    
}

@media only screen and (max-width: 600px) {
    .my_footer {
        position: relative;
        margin-top: 1rem
    }

    .brand_dark {
        margin-top: .5rem;
    }

    .dropdown-highchart {
        padding: 10px;
    }

    .filterButton {
        margin-bottom: 3rem;
    }

    .progress_loader {
        position: absolute;
        top: 300px;
        left: 450px;
        z-index: 1;
    }
}