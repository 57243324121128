a:hover {
  text-decoration: none;
}

/* CSS for Navbar */

.MacroCodexHomeNavBar .navbar-nav .nav-item .nav-link {
  font-weight: 500;
}


.MacroCodexHomeNavBar .navbar-nav li.active .nav-link {
  color: #FF6400;
  font-weight: 700;
}


.my_home,
.my_profile_personal_info,
.my_stripe_testing,
.my_form_validation,
.my_billing {
  min-height: 90vh
    /* border:2px solid red; */
}




.outline-button {
  border: 1px solid #FF6400;
  border-radius: 4px;
  padding: 5px 16px;
  background-color: #FFFFFF;
  /* height: 26px; */
  color: #FF6400;
  cursor: pointer;
}


.outline-button:hover {
  border: 1px solid #FF6400;
  background-color: #FF6400;
  color: #FFFFFF;
}

.outline-button>a {
  color: #FF6400;
}

.outline-button:hover a {
  color: #FFFFFF;
}

.outline-button:focus {
  outline: none;
}



.bg-F79A5E {
  background: #F79A5E;
}

.bg-FF6400 {
  background: #FF6400;
}

.txt-ff6400 {
  color: #ff6400;
}

.txt-316fe8 {
  color: #316FE8
}

.txt-18a0fb {
  color: #18a0fb;
}

.txt-4F4F4F {
  color: #4F4F4F
}


.fontSize14 {
  font-size: 14px;
}

.eyeIconWrapper {
  position: absolute;
  right: 24px;
  cursor: pointer;
  top: 6px;
}

.positionRelative {
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.container-new {
  width: 1280px;
}

.div-flex {
  display: flex;
}

.justify-spaceBetween {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.bg-color-nav {
  background-color: #FFFFFF;
}

.mt35 {
  margin-top: 35px !important;
}

.pr-32 {
  padding-right: 32px;
}

.nav-padding {
  padding: .5rem 0;
}

.nav-user-name {
  padding: .5rem .5rem !important;
}

.container-padding {
  padding: 0 7px;
}

.container-body {
  max-width: 1280px;
}

.footer-space {
  padding: 0 14px;
}

.justify-righ {
  justify-content: right;
}

.MuiPaper-root {
  display: flex;
  justify-content: center;
}

.pt-4-5 {
  padding-top: 2.3rem;
}

.Toastify__progress-bar--success, .Toastify__progress-bar--error {
  background-color: transparent;
}