/* @media only screen and (min-width: 1025px){ */
    .brand_dark_content{
        /* border:1px solid red; */
        height:560px;
        background-color:rgb(31, 24, 54);
        color:white;
        display:flex;
        justify-content:center;
        align-items: center;
    }
/* } */


.highcharts-label{
    fill:white !important;
    color:white !important;
}


text{
    fill:white !important;    
}

/* Indicator pivot points css start */

.highcharts-data-label-color-1 text{
fill:blue !important;
font-weight: normal;

}

/* Indicator pivot points css end */

.highcharts-label-box{
    fill: rgb(31, 24, 54) !important;
    stroke:#BBBAC5;
}


.highcharts-color-1{
    fill:yellow;
}



.highcharts-button-box{
    fill: rgb(31, 24, 54) !important;
    stroke:#BBBAC5
}


.highcharts-background{
    fill:#352E57;

}


.highcharts-tracker-line{
    stroke:white;
    stroke-width:2px;
}

.highcharts-grid-line {
	stroke-width: .5px;
}

.highcharts-credits{
    display:none
}

.highcharts-title{
    font-weight: lighter;
    font-size: 20px;
}

tspan{
    font-weight: lighter !important
}


