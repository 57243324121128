.loader_container {
    background-color: #ffffff;
    position: fixed;
    z-index: 1100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.loader_spinner {
    width: 4rem;
    height: 4rem;
    color: #ff6400;
}